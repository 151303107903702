import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { TileNavigation } from "../components/tile-navigation"
import { Headline3 } from "../components/typography"


const NotFoundPage: React.FC = () => {
  return (
    <Layout title="Sorry, this page not exist">
      <Main>
        <ImageWrapperMobile>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375.015 279.285">
            <g id="error" transform="translate(-232.707)">
              <path id="Path_2219" data-name="Path 2219" d="M622.938,279.285A139.642,139.642,0,1,1,762.581,139.642,139.642,139.642,0,0,1,622.938,279.285" transform="translate(-201.507)" />
              <text id="_404" data-name="404" transform="translate(415.992 173.027)" fill="#1f1f1f" fontSize="110" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700"><tspan x="-91.74" y="0">404</tspan></text>
              <path id="Path_2220" data-name="Path 2220" d="M878.716,121.67l10.85-8.473a5.95,5.95,0,0,0,1.027-8.352L884.1,96.531a5.952,5.952,0,0,0-8.351-1.027l-10.85,8.473Z" transform="translate(-360.612 -39.294)" fill="#3b3b3b" />
              <path id="Path_2221" data-name="Path 2221" d="M837.41,151.865l21.668-16.922a7.688,7.688,0,0,0,1.326-10.792h0l-18.79-24.06a7.688,7.688,0,0,0-10.791-1.327l-21.668,16.921Z" transform="translate(-337.371 -40.499)" fill="#444" />
              <path id="Path_2222" data-name="Path 2222" d="M797.931,128.7l31.62,40.489a1.009,1.009,0,0,0,1.416.174l3.18-2.483a1.009,1.009,0,0,0,.174-1.417L802.7,124.977a1.009,1.009,0,0,0-1.416-.174l-3.18,2.484a1.009,1.009,0,0,0-.174,1.416" transform="translate(-332.602 -51.946)" fill="#444" />
              <path id="Path_2223" data-name="Path 2223" d="M852.9,151.865l21.668-16.922a7.688,7.688,0,0,0,1.326-10.792h0l-18.79-24.06a7.688,7.688,0,0,0-10.791-1.327c18.484,27.389.484,45.288.484,45.288Z" transform="translate(-352.865 -40.499)" fill="#3b3b3b" />
              <rect id="Rectangle_4408" data-name="Rectangle 4408" width="2.217" height="45.905" transform="matrix(0.788, -0.615, 0.615, 0.788, 471.782, 75.186)" fill="#3b3b3b" />
              <path id="Path_2224" data-name="Path 2224" d="M873.319,151.952l12.51-9.77a7.689,7.689,0,0,0,1.326-10.792L873.964,114.5c13.321,17.057-.645,37.452-.645,37.452" transform="translate(-364.124 -47.74)" fill="#171817" />
              <rect id="Rectangle_4409" data-name="Rectangle 4409" width="1.437" height="22.449" transform="matrix(0.788, -0.615, 0.615, 0.788, 506.728, 62.775)" fill="#171817" />
              <path id="Path_2225" data-name="Path 2225" d="M891.718,119.765l8.407-6.566a5.949,5.949,0,0,0,1.027-8.351l-6.495-8.316a5.933,5.933,0,0,0-4.433-2.282c11.712,11.721.221,23.881.221,23.881Z" transform="translate(-371.172 -39.296)" fill="#171817" />
              <path id="Path_2226" data-name="Path 2226" d="M563.713,355.233l-10.85,8.473a5.949,5.949,0,0,1-8.351-1.027l-6.494-8.314a5.953,5.953,0,0,1,1.027-8.352l10.849-8.473Z" transform="translate(-223.797 -140.734)" fill="#3b3b3b" />
              <path id="Path_2227" data-name="Path 2227" d="M600.927,324l-21.669,16.922a7.689,7.689,0,0,1-10.792-1.329v0l-18.79-24.06A7.689,7.689,0,0,1,551,304.74l21.669-16.922Z" transform="translate(-228.504 -120.004)" fill="#444" />
              <path id="Path_2228" data-name="Path 2228" d="M592.01,277.456l31.62,40.489a1.009,1.009,0,0,1-.174,1.416h0l-3.179,2.483a1.009,1.009,0,0,1-1.416-.174l-31.62-40.489a1.009,1.009,0,0,1,.174-1.417l3.179-2.483a1.009,1.009,0,0,1,1.417.174" transform="translate(-244.756 -115.521)" fill="#444" />
              <path id="Path_2229" data-name="Path 2229" d="M611.975,289.9l-4.815-6.166,14.747-11.517a3.912,3.912,0,0,1,4.815,6.166Z" transform="translate(-253.15 -113.155)" fill="#737373" />
              <path id="Path_2230" data-name="Path 2230" d="M634.777,319.1l-4.815-6.166,14.747-11.517a3.912,3.912,0,1,1,4.815,6.166Z" transform="translate(-262.658 -125.33)" fill="#737373" />
              <path id="Path_2231" data-name="Path 2231" d="M600.927,336.1l-21.669,16.922a7.689,7.689,0,0,1-10.792-1.329v0l-18.79-24.06A7.689,7.689,0,0,1,551,316.841c22.094,24.57,43.821,11.444,43.821,11.444Z" transform="translate(-228.504 -132.105)" fill="#3b3b3b" />
              <rect id="Rectangle_4410" data-name="Rectangle 4410" width="2.217" height="45.905" transform="matrix(0.788, -0.615, 0.615, 0.788, 342.42, 169.179)" fill="#3b3b3b" />
              <path id="Path_2232" data-name="Path 2232" d="M596.935,356.094l-12.51,9.77a7.689,7.689,0,0,1-10.792-1.329l-13.19-16.891c13.32,17.057,36.492,8.45,36.492,8.45" transform="translate(-233.672 -144.948)" fill="#171817" />
              <rect id="Rectangle_4411" data-name="Rectangle 4411" width="1.437" height="22.449" transform="matrix(0.788, -0.615, 0.615, 0.788, 322.526, 199.595)" fill="#171817" />
              <rect id="Rectangle_4412" data-name="Rectangle 4412" width="2.739" height="7.823" transform="matrix(0.788, -0.616, 0.616, 0.788, 354.01, 170.584)" fill="#595959" />
              <rect id="Rectangle_4413" data-name="Rectangle 4413" width="2.739" height="7.823" transform="matrix(0.788, -0.616, 0.616, 0.788, 367.305, 187.608)" fill="#595959" />
              <path id="Path_2233" data-name="Path 2233" d="M561.275,365.7l-8.407,6.566a5.95,5.95,0,0,1-8.352-1.027l-6.494-8.316a5.934,5.934,0,0,1-1.138-4.856c8.532,14.2,23.116,6,23.116,6Z" transform="translate(-223.799 -149.292)" fill="#171817" />
              <path id="Path_2234" data-name="Path 2234" d="M639.121,161.567l-14.075-1.981a1.957,1.957,0,1,1,.545-3.875l14.076,1.981a1.957,1.957,0,0,1-.546,3.876" transform="translate(-259.906 -64.915)" fill="#f58600" />
              <path id="Path_2235" data-name="Path 2235" d="M684.138,126.592a1.956,1.956,0,0,1-1.665-2.21l1.981-14.076a1.957,1.957,0,0,1,3.876.545l-1.982,14.076a1.956,1.956,0,0,1-2.21,1.665" transform="translate(-284.544 -45.289)" fill="#f58600" />
              <path id="Path_2236" data-name="Path 2236" d="M654.849,134.138a1.952,1.952,0,0,1-1.29-.761l-8.308-11.03a1.956,1.956,0,1,1,3.126-2.354l8.308,11.03a1.956,1.956,0,0,1-1.835,3.115" transform="translate(-268.868 -49.706)" fill="#f58600" />
              <path id="Path_2237" data-name="Path 2237" d="M809.97,301.291l14.076,1.981a1.957,1.957,0,0,1-.545,3.876l-14.076-1.981a1.957,1.957,0,0,1,.546-3.876" transform="translate(-336.781 -125.613)" fill="#f58600" />
              <path id="Path_2238" data-name="Path 2238" d="M773.6,327.617a1.956,1.956,0,0,1,1.665,2.21h0L773.283,343.9a1.957,1.957,0,0,1-3.876-.546l1.982-14.076a1.956,1.956,0,0,1,2.21-1.665" transform="translate(-320.79 -136.589)" fill="#f58600" />
              <path id="Path_2239" data-name="Path 2239" d="M798.363,322.249a1.949,1.949,0,0,1,1.29.761l8.308,11.029a1.957,1.957,0,0,1-3.126,2.354l-8.307-11.03a1.956,1.956,0,0,1,1.835-3.115" transform="translate(-331.942 -134.352)" fill="#f58600" />
              <path id="Path_2240" data-name="Path 2240" d="M322.526,313.546a4.375,4.375,0,0,0-6.106-.995c-31.486,22.669-58.711,26.42-83.713,22.008.019-.008.008,8.779,0,8.824a108.415,108.415,0,0,0,18.222,1.542c21.395,0,44.706-6.629,70.6-25.273a4.374,4.374,0,0,0,.994-6.106" transform="translate(0 -98.951)" fill="#3b3b3b" />
              <path id="Path_2241" data-name="Path 2241" d="M978.691,47.084a80.649,80.649,0,0,0-10.623-2.756c-25.234-4.877-50.3,2.77-76.652,23.379a4.375,4.375,0,0,0,5.391,6.892c33-25.814,58.925-26.738,81.884-18.272C978.691,56.315,978.689,47.1,978.691,47.084Z" transform="translate(-370.969 -8.12)" fill="#3b3b3b" />
            </g>
          </svg>
        </ImageWrapperMobile>
        <ImageWrapper>
          <svg id="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440.999 506.988">
            <path id="Path_2219" data-name="Path 2219" d="M723.3,480c-132.551,0-240-107.454-240-240S590.75,0,723.3,0s240,107.454,240,240-107.454,240-240,240" transform="translate(1.008)" />
            <text id="_404" data-name="404" transform="translate(715.836 299.479)" fill="#1f1f1f" fontSize="191" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700"><tspan x="-159.294" y="0">404</tspan></text>
            <path id="Path_2220" data-name="Path 2220" d="M888.646,141.381l18.648-14.562a10.227,10.227,0,0,0,1.765-14.354L897.9,98.174a10.23,10.23,0,0,0-14.353-1.765L864.9,110.972Z" transform="translate(1.805 0.197)" fill="#3b3b3b" />
            <path id="Path_2221" data-name="Path 2221" d="M857.716,191.2l37.241-29.084a13.213,13.213,0,0,0,2.279-18.549l0,0L864.94,102.214a13.213,13.213,0,0,0-18.546-2.281l-37.24,29.083Z" transform="translate(1.688 0.203)" fill="#444" />
            <path id="Path_2222" data-name="Path 2222" d="M798.085,131.659l54.344,69.588a1.735,1.735,0,0,0,2.434.3l5.465-4.268a1.734,1.734,0,0,0,.3-2.435l-54.344-69.588a1.735,1.735,0,0,0-2.434-.3l-5.465,4.269a1.734,1.734,0,0,0-.3,2.434" transform="translate(1.664 0.26)" fill="#444" />
            <path id="Path_2223" data-name="Path 2223" d="M857.639,191.2l37.241-29.084a13.213,13.213,0,0,0,2.279-18.549l0,0-32.293-41.352a13.213,13.213,0,0,0-18.546-2.281c31.768,47.073.832,77.835.832,77.835Z" transform="translate(1.766 0.203)" fill="#3b3b3b" />
            <rect id="Rectangle_4408" data-name="Rectangle 4408" width="3.811" height="78.896" transform="matrix(0.788, -0.615, 0.615, 0.788, 810.84, 129.221)" fill="#3b3b3b" />
            <path id="Path_2224" data-name="Path 2224" d="M873.319,178.868l21.5-16.791a13.214,13.214,0,0,0,2.279-18.548L874.428,114.5c22.894,29.315-1.109,64.368-1.109,64.368" transform="translate(1.822 0.238)" fill="#171817" />
            <rect id="Rectangle_4409" data-name="Rectangle 4409" width="2.469" height="38.582" transform="matrix(0.788, -0.615, 0.615, 0.788, 870.9, 107.891)" fill="#171817" />
            <path id="Path_2225" data-name="Path 2225" d="M892.791,138.1l14.449-11.285a10.225,10.225,0,0,0,1.766-14.353L897.844,98.171a10.2,10.2,0,0,0-7.619-3.921c20.129,20.144.38,41.044.38,41.044Z" transform="translate(1.857 0.197)" fill="#171817" />
            <path id="Path_2226" data-name="Path 2226" d="M583.085,367.948l-18.648,14.562a10.225,10.225,0,0,1-14.353-1.766l-11.16-14.29A10.231,10.231,0,0,1,540.69,352.1l18.647-14.562Z" transform="translate(1.119 0.705)" fill="#3b3b3b" />
            <path id="Path_2227" data-name="Path 2227" d="M638.931,350,601.69,379.086a13.216,13.216,0,0,1-18.549-2.284v0l-32.294-41.352a13.215,13.215,0,0,1,2.281-18.546l37.243-29.084Z" transform="translate(1.143 0.6)" fill="#444" />
            <path id="Path_2228" data-name="Path 2228" d="M595.593,277.734l54.344,69.588a1.734,1.734,0,0,1-.3,2.434h0l-5.464,4.268a1.734,1.734,0,0,1-2.434-.3l-54.344-69.588a1.734,1.734,0,0,1,.3-2.435l5.464-4.267a1.735,1.735,0,0,1,2.435.3" transform="translate(1.225 0.578)" fill="#444" />
            <path id="Path_2229" data-name="Path 2229" d="M615.436,303.208l-8.276-10.6L632.5,272.818a6.723,6.723,0,1,1,8.276,10.6Z" transform="translate(1.268 0.566)" fill="#737373" />
            <path id="Path_2230" data-name="Path 2230" d="M638.238,332.407l-8.276-10.6,25.345-19.793a6.723,6.723,0,1,1,8.276,10.6Z" transform="translate(1.314 0.627)" fill="#737373" />
            <path id="Path_2231" data-name="Path 2231" d="M638.931,349.942,601.69,379.025a13.216,13.216,0,0,1-18.549-2.284v0l-32.294-41.352a13.215,13.215,0,0,1,2.281-18.546c37.973,42.228,75.315,19.669,75.315,19.669Z" transform="translate(1.143 0.66)" fill="#3b3b3b" />
            <rect id="Rectangle_4410" data-name="Rectangle 4410" width="3.811" height="78.896" transform="matrix(0.788, -0.615, 0.615, 0.788, 588.508, 290.764)" fill="#3b3b3b" />
            <path id="Path_2232" data-name="Path 2232" d="M623.161,362.166l-21.5,16.791a13.215,13.215,0,0,1-18.548-2.284l-22.669-29.029c22.893,29.315,62.718,14.522,62.718,14.522" transform="translate(1.17 0.725)" fill="#171817" />
            <rect id="Rectangle_4411" data-name="Rectangle 4411" width="2.469" height="38.582" transform="matrix(0.788, -0.615, 0.615, 0.788, 554.316, 343.039)" fill="#171817" />
            <rect id="Rectangle_4412" data-name="Rectangle 4412" width="4.708" height="13.446" transform="matrix(0.788, -0.616, 0.616, 0.788, 608.428, 293.178)" fill="#595959" />
            <rect id="Rectangle_4413" data-name="Rectangle 4413" width="4.708" height="13.446" transform="matrix(0.788, -0.616, 0.616, 0.788, 631.277, 322.438)" fill="#595959" />
            <path id="Path_2233" data-name="Path 2233" d="M578.892,371.182l-14.449,11.284a10.227,10.227,0,0,1-14.354-1.766l-11.161-14.293a10.2,10.2,0,0,1-1.955-8.345c14.663,24.413,39.73,10.315,39.73,10.315Z" transform="translate(1.119 0.746)" fill="#171817" />
            <path id="Path_2234" data-name="Path 2234" d="M650.447,165.79l-24.19-3.405a3.363,3.363,0,1,1,.937-6.66l24.191,3.4a3.363,3.363,0,0,1-.938,6.661" transform="translate(1.301 0.324)" fill="#f58600" />
            <path id="Path_2235" data-name="Path 2235" d="M685.348,139.506a3.362,3.362,0,0,1-2.861-3.8l3.405-24.191a3.363,3.363,0,1,1,6.661.937l-3.406,24.191a3.362,3.362,0,0,1-3.8,2.861" transform="translate(1.424 0.227)" fill="#f58600" />
            <path id="Path_2236" data-name="Path 2236" d="M662.031,144.863a3.354,3.354,0,0,1-2.218-1.308L645.534,124.6a3.363,3.363,0,1,1,5.372-4.045l14.279,18.956a3.362,3.362,0,0,1-3.154,5.353" transform="translate(1.346 0.248)" fill="#f58600" />
            <path id="Path_2237" data-name="Path 2237" d="M811.572,301.305l24.191,3.405a3.363,3.363,0,0,1-.937,6.661l-24.192-3.405a3.363,3.363,0,0,1,.938-6.661" transform="translate(1.686 0.629)" fill="#f58600" />
            <path id="Path_2238" data-name="Path 2238" d="M776.625,327.631a3.362,3.362,0,0,1,2.862,3.8h0l-3.405,24.191a3.363,3.363,0,0,1-6.661-.938l3.406-24.191a3.362,3.362,0,0,1,3.8-2.861" transform="translate(1.605 0.684)" fill="#f58600" />
            <path id="Path_2239" data-name="Path 2239" d="M799.965,322.263a3.35,3.35,0,0,1,2.218,1.308l14.279,18.955a3.363,3.363,0,1,1-5.373,4.046l-14.277-18.956a3.362,3.362,0,0,1,3.154-5.353" transform="translate(1.66 0.672)" fill="#f58600" />
            <path id="Path_2240" data-name="Path 2240" d="M554.317,368.325a7.52,7.52,0,0,0-10.495-1.71c-95.948,69.078-167.662,35.972-237.014,3.955-37.563-17.34-73.56-33.954-111-32.911q-.717-2.536-1.517-5.024c-10.64-33.109-31.748-59.121-61.043-75.225-34.1-18.746-78.93-24.4-133.248-16.832v15.158c.155-.013.311-.021.467-.044,51.771-7.448,94.008-2.434,125.538,14.9,26.235,14.421,44.392,36.844,53.969,66.646q.3.926.582,1.858a116.286,116.286,0,0,0-22.9,6.177c-45.1,17.182-73.661,49.11-78.353,87.6C76,460.012,85.746,485.8,104.141,498.56a43.1,43.1,0,0,0,24.874,7.934,51.918,51.918,0,0,0,23.931-6.255c18.445-9.581,34.515-34.522,42.985-66.718a189.885,189.885,0,0,0,3.362-80.74c33.341.111,66.572,15.452,101.213,31.443,39.692,18.323,82.379,38.035,130.761,38.032,36.771,0,76.835-11.393,121.343-43.436a7.518,7.518,0,0,0,1.709-10.495M181.387,429.7c-7.3,27.738-20.851,49.654-35.373,57.2-12.087,6.279-23.6,6.042-33.3-.687-13.654-9.474-21.082-30.177-18.48-51.516,3.993-32.767,29.063-60.237,68.778-75.367a98.947,98.947,0,0,1,21.186-5.5,174.1,174.1,0,0,1-2.81,75.877" transform="translate(0 0.494)" fill="#3b3b3b" />
            <path id="Path_2241" data-name="Path 2241" d="M1439.144,35.564c-32.647,8.984-56.725,23.173-73.576,43.38-14.861,17.82-22.161,37.987-28.6,55.78-9.153,25.288-17.06,47.127-43.529,57.831-25.129,10.164-51.389,3.735-72.043-17.639a94.916,94.916,0,0,1-14.023-18.786,64.193,64.193,0,0,0,18.31-10.7c17.51-14.223,29.921-31.386,35.893-49.634,5.156-15.752,5.458-32.2.854-46.32-5.786-17.741-18.318-29.224-32.706-29.967-9.3-.473-26.966,3.365-40.814,34.5a121.491,121.491,0,0,0-3.534,90.049,55.246,55.246,0,0,1-12.353-2.7c-16.1-5.346-33.663-17.633-52.26-30.64-27.7-19.37-59.085-41.326-96.4-48.539-43.368-8.382-86.457,4.761-131.74,40.181a7.519,7.519,0,0,0,9.265,11.845c93.289-72.971,155.43-29.508,210.252,8.836,27.318,19.109,53.591,37.471,79.823,36.213a111.164,111.164,0,0,0,18.617,26.105c17.049,17.642,38.041,26.882,59.467,26.882a77.171,77.171,0,0,0,29.028-5.753c32.688-13.219,42.522-40.384,52.03-66.655,12.481-34.481,25.388-70.106,88.038-88.643ZM1195.328,123.5a106.08,106.08,0,0,1,7.318-63.371c7.266-16.336,16.564-25.612,25.617-25.612q.341,0,.682.017c7.9.408,15.432,8.107,19.184,19.613,10.218,31.324-10.815,62.467-31.93,79.618a47.484,47.484,0,0,1-15.386,8.683,106.815,106.815,0,0,1-5.485-18.947" transform="translate(1.855 0.041)" fill="#3b3b3b" />
          </svg>
        </ImageWrapper>
        <Headline404>
          Sorry, this page<br /> does not exist
        </Headline404>
      </Main>
      <TileNavigation top="team" bottom="office" />
    </Layout>
  )
}

const Main = styled.main.attrs({ className: "x-background relative pt-8 pb-20 tablet:py-16" })``;

const ImageWrapper = styled.div.attrs({ className: "w-full hidden tablet:block" })``;

const ImageWrapperMobile = styled.div.attrs({ className: "w-full tablet:hidden" })``;

const Headline404 = styled(Headline3).attrs({ className: "text-center absolute inset-x-0 bottom-2 tablet:bottom-8" })``;

export default NotFoundPage;